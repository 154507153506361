.txt-text {
  display: -webkit-inline-box;
  font-size: 2.25rem;
}
.txt-text span {
  padding: 6px 1rem 0rem 1rem;
  margin: 6px 6px 6px 0px;
  display: inline-block;
}
.text-pill {
  color: white;
  background: #1b1bd2;
  border-radius: 9999px;
}
@media screen and (max-width: 75rem) {
  .txt-text {
    font-size: 2.25rem;
  }
}
@media screen and (max-width: 48rem) {
  .txt-text {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 35rem) {
  .txt-text {
    font-size: 1.5rem;
  }
}
