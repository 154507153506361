.card-heading {
  color: #fff;
  font-size: 3rem !important;
  line-height: 50px !important;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  padding: 100px 0px;
  font-weight: 500 !important;
}

.box-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
}

.box-item {
  position: relative;
  -webkit-backface-visibility: hidden;
  width: 100%;
  max-width: 100%;
}

.flip-box {
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  perspective: 1000px;
  -webkit-perspective: 1000px;
}

.flip-box-front,
.flip-box-back {
  background-size: cover;
  background-position: center;
  border-radius: 40px;
  min-height: 400px;
  -ms-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  -ms-transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-box-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -ms-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
  opacity: 0.8;
}

.flip-box .inner {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 15px 8%;
  outline: 1px solid transparent;
  -webkit-perspective: inherit;
  perspective: inherit;
  z-index: 2;
  transform: translateY(-50%) translateZ(60px) scale(0.94);
  -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
  -ms-transform: translateY(-50%) translateZ(60px) scale(0.94);
  top: 50%;
  align-items: center;
}
@media screen and (max-width: 48rem) {
  .card-heading {
    font-size: 2.5rem !important;
  }
  .flip-box-front,
  .flip-box-back {
    min-height: 325px;
  }
}
@media screen and (max-width: 33rem) {
  .card-heading {
    font-size: 1.5rem !important;
  }
  .flip-box-front,
  .flip-box-back {
    min-height: 250px;
  }
}
@media screen and (min-width: 61rem) and (max-width: 78rem) {
  .card-heading {
    font-size: 2.5rem !important;
  }
}
@media screen and (min-width: 1380px) {
  .box-container {
    flex-direction: row;
    width: 350px;
    margin: auto;
  }
}

.flip-box:hover .flip-box-front {
  -ms-transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.flip-box:hover .flip-box-front h2 {
  display: none;
}
.flip-box:hover .flip-box-back {
  -ms-transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
