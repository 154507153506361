.artist-name:hover .arrow {
  margin-right: 10px;
  display: inline-block !important;
}
@media screen and (min-width: 48rem) {
  .txt-play-box {
    position: relative;
    top: calc(50% - 4rem);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
