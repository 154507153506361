article.accordion {
  display: block;
}
article.accordion section {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  color: #1a1a1a;
  background-color: #1a1a1a;
}
article.accordion section h2 {
  text-align: center;
  font-weight: 400;
  padding: 12px;
  text-transform: uppercase;
  color: #ddd;
}

article.accordion section h3,
article.accordion section p {
  display: none;
}
.accordion-content.active {
  transition: opacity 1s ease 1s;
  opacity: 1;
  display: block;
}
article.accordion section h2 a {
  display: block;
  width: 100%;
  line-height: 4rem;
  text-decoration: none;
  color: inherit;
  outline: 0 none;
}
article.accordion section:target {
  padding: 3%;
  color: #fff;
  background: url('/images/txt-play-images/Feature_bg.png') no-repeat;
  background-size: cover;
  display: grid;
  place-items: center;
}
article.accordion section:target h2 {
  display: none;
}

article.accordion section,
article.accordion section h2 {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.accordion-panel {
  background: url('/images/txt-play-images/Feature_bg.png') no-repeat;
  background-size: 100% 100%;
  color: #fff;
  display: none;
  place-items: center;
  border-radius: 20px;
  padding: 8px;
  text-align: justify;
}

article.accordion section.active {
  padding: 3%;
  color: #fff;
  background: url('/images/txt-play-images/Feature_bg.png') no-repeat;
  background-size: cover;
  display: grid;
  place-items: center;
  transition: all 1s ease;
}

article.accordion section.active h2 {
  display: none;
}
article.accordion section.active h3,
article.accordion section.active p {
  display: block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media screen and (min-width: 48rem) {
  article.accordion section:target {
    width: 48%;
  }
}
@media screen and (max-width: 48rem) {
  article.accordion section.active {
    padding: 5%;
  }
}
@media screen and (max-width: 65rem) {
  article.accordion section h2 a {
    line-height: 2.5rem;
  }
}
