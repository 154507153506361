body {
  overflow-x: hidden;
}
.react-blur-player video {
  object-fit: cover;
  object-position: center;
  -webkit-filter: blur(2rem);
  -moz-filter: blur(2rem);
  -o-filter: blur(2rem);
  -ms-filter: blur(2rem);
  filter: blur(2rem);
}
.react-player video {
  object-fit: cover;
  object-position: center;
  border-radius: 40px;
}
h1.text-animation {
  position: absolute;
  font-weight: 100;
  color: white;
  white-space: nowrap;
}
.text-animation.top-left,
.text-animation.top-right {
  top: 10vh;
}
.text-animation.bottom-left,
.text-animation.bottom-right {
  bottom: 10vh;
}
.text-animation.top-left,
.text-animation.bottom-left {
  left: 2vw;
}
.text-animation.top-right,
.text-animation.bottom-right {
  right: 2vw;
}

/* @media (max-width: 768px) {
  .text-animation.top-left,
  .text-animation.top-right,
  .text-animation.bottom-left,
  .text-animation.bottom-right {
    top: calc(50vh - 5vh);

  }
} */
